<template>
  <div id="xgb" class="pagelg">

    </meta>

    <!-- <description>新工宝是以灵活就业为核心打造的临时用工信息共享平台，整合建筑、工厂、装修家政、家电维修、司机物流、酒店餐饮、影视演绎等行业资源，海量真实短期日结、月结招工找活信息平台，找工作，就上新工宝！</description> -->
    <!-- <Keywords>新工宝,招聘网,找工作,日结,兼职,零工,临工,招聘平台</Keywords> -->
    <div id=Pchome v-show="pdbsf === 0">

      <div class="mainbox" id="shouye">
        <!-- <img src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/25gunagwangbanner.png" class="bj2"> -->
        <img src="../assets/xgbbanner.png" class="bj2">

        <img src="../assets/简介@2x.png" class="img7">

        <!-- <img src="../assets/Group 238458@2x.png" class="logo1">
        <img src="../assets/Group 238457@2x.png" class="logo2">
        <img src="../assets/image 14@2x.png" class="logo3"> -->






      </div>

      <div class="lgtitle">

      </div>

      <div @click="handleClick" class="hh">


        <p v-if="text1 !== 1" class="pt1" style="" data-target="#shouye" data-key="1">首页</p>
        <p v-if="text1 === 1" class="pty1" style="" data-target="#shouye" data-key="1">首页</p>
        <p v-if="text1 !== 2" class="pt2" style="" data-target="#chanpin" data-key="2">产品特色
        </p>
        <p v-if="text1 === 2" class="pty2" style="" data-target="#chanpin" data-key="2">产品特色</p>
        <p v-if="text1 !== 3" class="pt3" style="" data-target="#news" data-key="3">
          新闻咨询</p>
        <p v-if="text1 === 3" class="pty3" style="" data-target="#news" data-key="3">新闻咨询</p>
        <p v-if="text1 !== 4" class="pt4" style="" data-target="#guanyuwomeng" data-key="4">
          关于我们</p>
        <p v-if="text1 === 4" class="pty4" style="" data-target="#guanyuwomeng" data-key="4">关于我们
        </p>
        <div>
          <!-- <img src="../assets/Frame 21@2x.png" class="logo4"> -->
          <img  src="../assets/logo4.png" class="logo4" @click="toHome">
        </div>
      </div>






      <div>
        <img src="../assets/Group 238459@2x.png" class="img11" id="chanpin">

      </div>

      <div>
        <!-- <p class="text2">天若有情天亦老,找工就上新工宝</p> -->
        <p class="text2">萝卜之旅，让心灵回归纯净之旅</p>
        <div class="img2">
          <!-- <img src="../assets/Group 238482.png" v-if="count1 !== 1" class="img9" @mouseover=" imageUpdates(1)">
          <img src="../assets/Group 238487.png" v-if="count1 === 1" class="img10"> -->
          <div v-if="count1 !== 1" class="img9 img9font" @mouseover=" imageUpdates(1)">
            热门酒店推荐
          </div>
          <div v-if="count1 === 1" class="img10 img10font">
            热门酒店推荐
          </div>

        </div>
        <div class="img2">
          <!-- <img src="../assets/Group 238481.png" v-if="count1 !== 2" class="img9" @mouseover="imageUpdates(2)">
          <img src="../assets/Group 238486.png" v-if="count1 === 2" class="img10"> -->
          <div v-if="count1 !== 2" class="img9 img9font" @mouseover=" imageUpdates(2)">
            疯狂砍价
          </div>
          <div v-if="count1 === 2" class="img10 img10font">
            疯狂砍价
          </div>
        </div>
        <div class="img2">
          <!-- <img src="../assets/Group 238480.png" v-if="count1 !== 3" class="img9" @mouseover="imageUpdates(3)">
          <img src="../assets/Group 238485.png" v-if="count1 === 3" class="img10"> -->
          <div v-if="count1 !== 3" class="img9 img9font" @mouseover=" imageUpdates(3)">
            快速拼团
          </div>
          <div v-if="count1 === 3" class="img10 img10font">
            快速拼团
          </div>
        </div>
        <!-- <div class="img2">
          <img src="../assets/Group 238483.png" v-if="count1 !== 4" class="img9" @mouseover="imageUpdates(4)">
          <img src="../assets/Group 238484.png" v-if="count1 === 4" class="img10">
        </div> -->

        <!--新闻部分-->
        <div class="mainbox2" id="news">
          <div>
            <img src="../assets/Group 238460@2x.png" class="img6">
          </div>

          <el-row>
            <el-col :span="6" v-for="news, index in list" :key="index">

              <el-card :body-style="{ padding: '0px' }" class="card">

                <img :src="news.pressPhoto" class="image" @click="jump(news)">

                <div style="padding: 0px;">
                  <br>
                  <span>
                    <p class="ph1" style=" ">{{ news.day }}</p>
                    <p class="ph2" style=" ">{{ news.year }}.{{ news.month }}</p>
                  </span>


                  <span>
                    <P class="p5" style="">{{
      news.newsHeadlines }}
                    </P>
                  </span>

                  <span>
                    <p class="p4" style=""> {{ news.text }}
                    </p>
                  </span>

                  <span>
                    <img src="../assets/Frame 28@2x.png" class="img12" @click="jump(news)">
                  </span>

                </div>
              </el-card>

            </el-col>
          </el-row>
          <div>
            <img src="../assets/Down (下)@2x(1).png" style="" class="logo5" @click=" getListMin()">
          </div>
          <div>
            <img src="../assets/Down (下)@2x.png" style="" class="logo6" @click=" getListMax()">
          </div>
          <div>
            <img src="../assets/Group 238478@2x.png" class="img13" style="" v-if="newsindex == 0">
            <img src="../assets/Group 2384782@2x.png" class="img13"
              v-if="newsindex < list2.length - 3 && newsindex != 0">
            <img src="../assets/Group 238479@2x.png" class="img13" v-if="newsindex >= list2.length - 3">

          </div>
        </div>
      </div>

      <!-- 产品图片群  -->
      <div class="img3">

        <!-- <img src="../assets/大厅 1@2x.png" class="img4" v-if="count1 === 1"> -->
        <img src="../assets/maintwo1.png" class="img4" v-if="count1 === 1">
        <!-- <img src="../assets/官网2.png" class="img4" v-if="count1 === 2"> -->
        <img src="../assets/maintwo2.png" class="img4" v-if="count1 === 2">
        <!-- <img src="../assets/官网3.png" class="img4" v-if="count1 === 3"> -->
        <img src="../assets/maintwo3.png" class="img4" v-if="count1 === 3">
        <!-- <img src="../assets/官网4.png" class="img4" v-if="count1 === 4"> -->
      </div>
      <!-- 证书 -->
      <div class="mainbox4" id="zhengshu">

        <img
          src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/02%E8%8D%A3%E8%AA%89%E5%B1%95%E7%A4%BAlogo.png"
          class="ry1">
        <span>
          <p class="p6">新工宝被评为AAA级信用企业、3A质量服务诚信企业、诚信经营示范企业等多项荣誉资质</p>
        </span>
        <img
          src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/02%E8%8D%A3%E8%AA%89%E8%AF%81%E4%B9%A6.png"
          class="zs1">
      </div>
      <!--关于我们-->
      <div class="mainbox3">
        <div>
          <img src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16Group%20238470@2x.png" class="img8"
            id="guanyuwomeng">
        </div>
        <div class="text1" style=" ">
          <p class="p1">
            成都新工宝科技有限责任公司是以灵活就业为核心打造的临时用工信息共享平台，成都新工宝科技有限责任公司整合家政、司机物流、工厂、酒店餐饮、商务服务、建筑装修、家电维修、及其他各种个人技能人才资源，为用户提供智慧、精准的人力资源信息，快速解决用户的各种人力需求。
            成都新工宝科技有限责任公司致力于为灵活就业人群、兼职人群、建筑工人创建一个靠谱的、直接的、高效的互联网就业、创收信息服务中心。成都新工宝科技有限责任公司在互联网时代的大背景下，通过研发灵活就业的互联网新技术和构建数据库，解决招工市场存在的零散、复杂、信息
            不透明导致供需双方地位不对等的现实问题，为灵活就业人员提供真实、丰富、自由的工作岗位，为行业赋能，为社会提效。
          </p>
        </div>
      </div>
      <div class="mainbox">
        <!-- <img src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16Group%20238471@2x.png" class="bj3"> -->
        <img src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/25Group%20238471@2x.png" class="bj3"
          alt="">

      </div>
    </div>
    <div id="Azhome" v-show="pdbsf === 1">
      <div class="yd">
        <div id="ydshouye " class="ydshouye">
          <!-- <img src="../assets/az/移动端背景1.png" class="ydbj1"> -->
          <div style="position: relative; top: 310px;left: 0%; ">
            <img src="../assets/az/二维码.png" class="ydewm">
            <img src="../assets/az/苹果下载按钮.png" class="ydIosxz">
            <img src="../assets//az/安卓下载按钮.png" class="ydAndroidxz">
          </div>


        </div>
        <div id="ydchanpin">
          <div id="chanpinTPs">
            <img :src=chanpinTPsList[chanpinTPsindex] class="chanpinTP1">
          </div>
          <div id="dongxiaoTPs" class="dongxiaoTPs">
            <img src="../assets/az/动效图片左.png" class="dongxiaoTP1" @click="changechanpinTPsindex(1)">
            <img src="../assets/az/动效图片右.png" class="dongxiaoTP2" @click="changechanpinTPsindex(-1)">
          </div>

        </div>
        <div id="ydnews" class="ydnews">
          <div class="ydnewsbox">
            <p class="ydnewsP1">最新动态</p>
            <div class="newsNt">
              <router-link to="/news"> <img src="../assets/az/Frame 34.png"></router-link>
            </div>
          </div>
          <el-card v-for="(news, index) in list" :body-style="{ padding: '0px' }" class="Azcard" :key="news.id">
            <a :href="news.newsLinks" target="_blank">
              <img :src="news.pressPhoto" class="Azimg">
            </a>
            <div style="padding: 0px;">
              <span>
                <P class="AzT1" style="">{{
      news.newsHeadlines }}
                </P>
              </span>



              <p class="Azp2">
                {{ news.createTime}}

              </p>

            </div>

          </el-card>
        </div>
        <div id="ydHonorDisplay" class="ydHonorDisplay">
          <!-- <div id="ryzsbj">
           <img src="../assets/az/荣誉展示图.png" class="ydbj2">
           </div> -->
          <div>
            <img src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16%E8%AF%81%E4%B9%A6.png"
              class="ydzhengshu">
          </div>
        </div>
        <div id="ydguanyuwomeng" class="ydguanyuwomeng">
          <img src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16Group%20238506.png"
            class="ydguanyuwomeng1">

          <img src="https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16Group%20238492.png"
            class="ydguanyuwomeng2">

        </div>

      </div>
    </div>

  </div>

</template>

<script>
import { getnewList } from '@/api/content/news'



export default {
  name: 'HomeView',
  data() {
    return {
      //判断标识符
      pdbsf: 0,
      text1: 1,
      imga: 1,
      isPC: false,
      currentDate: new Date(),
      currentImageIndex: 0, // 从0开始计数  
      intervalId: null,
      currentPictureIndex: 1, // 当前图片索引  
      totalPictures: 4, // 总图片数量  
      count1: 1, // 辅助计数器，用于跟踪当前显示的图片  
      isMethodRunning: true,  //用于停止方法的标记
      total: 0,
      // 新闻列表
      list: [],
      //新闻全表
      list2: [],
      //展示新闻的索引
      newsindex: 0,
      queryParams: {
        pageNo: 1, //当前页数
        pageSize: 3, //每页显示记录数
        orderBy: 'createTime', //排序列
        isAsc: 'desc', //排序的方向 "desc" 或者 "asc".
        newsHeadlines: ''
      },
      rotationTimeout: null, // 用于存储定时器的变量  
      //轮播图列表
      chanpinTPsList: [
        'https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16Group%20238488.png',
        'https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16Group%20238489.png',
        'https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16Group%20238490.png',
        'https://xingongbao.oss-cn-hangzhou.aliyuncs.com/file/2024/04/16Group%20238491.png',
      ],
      chanpinTPsindex: 0
    }
  },

  components: {

  },
  methods: {
    /** 查询列表 */
    getListMax() {
      console.log(1);
      if (this.list2.length > this.newsindex + 3) {
        this.newsindex += 1;
        this.list = this.list2.slice(this.newsindex, this.newsindex + 3)
      } else {
        this.$message({
          message: '没有更多数据啦',
          type: 'warning'
        });

      }
    },
    /** 查询列表 */
    getListMin() {
      console.log(1);
      if (this.newsindex > 0) {
        this.newsindex -= 1;
        this.list = this.list2.slice(this.newsindex, this.newsindex + 3)
      } else {
        this.$message({
          message: '没有更多数据啦',
          type: 'warning'
        });

      }
    },
    getList() {
      getnewList()
        .then(response => {
          // 请求成功时处理响应数据  
          console.log(response.data);
          this.list2 = response.data
          if (this.list2.length > 3) {
            this.list = this.list2.slice(0, 3);
          } else {
            this.list = this.list2
          }
        })
        .catch(error => {
          // 请求失败时处理错误  
          console.error(error);
        });
    },

    handleClick(event) {
      const target = event.target;
      if (target.tagName.toLowerCase() === 'p') {
        const targetId = target.getAttribute('data-target');

        const sum = target.getAttribute('data-key'); // 读取 data-key 属性  
        if (sum) { // 确保 sum 不是 null 或 undefined  
          this.text1 = parseInt(sum, 10);

          this.scrollToTargetById(targetId);
        }
      }
    },
    scrollToTargetById(id) {
      const target3 = this.$el.querySelector(id);
      if (target3) {
        const position = target3.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: position,
          behavior: 'smooth' // 平滑滚动  
        });
      }
    },     /** 图片更新 */
    imageUpdates(digit) {
      this.isMethodRunning = false;
      this.count1 = digit;
    },
    /** 图片轮换函数 */
    // pictureRotation() {
    //   if (!this.isMethodRunning) {
    //     return;
    //   }
    //   // 清除之前的定时器，避免重复设置  
    //   if (this.rotationTimeout) {
    //     clearTimeout(this.rotationTimeout);
    //   }

    //   // 检查是否达到图片总数，如果是则重置索引和计数器  
    //   if (this.currentPictureIndex > this.totalPictures) {

    //     this.rotationTimeout = setTimeout(() => {
    //       this.currentPictureIndex = 1;
    //       this.count1 = 1;
    //       this.pictureRotation(); // 递归调用自身以实现轮换效果  
    //     }, 3000);
    //   }
    //   // 在这里可以更新显示的图片，例如通过绑定currentPictureIndex到图片的src属性  
    //   // 递增索引和计数器  
    //   this.currentPictureIndex++;
    //   this.count1++;

    //   // 检查计数器是否超过图片总数，如果是则重置  
    //   if (this.count1 > this.totalPictures) {

    //     this.count1 = 1;
    //   }

    //   // 设置下一个轮换的时间  
    //   setTimeout(() => {

    //     this.pictureRotation(); // 递归调用自身以实现轮换效果  
    //   }, 3000); // 延迟1000毫秒，即1秒  
    // },
    jump(news) {
      console.log(news);
      if (news.newsType == 1) {
        window.open(news.newsLinks, '_blank')

      } else {

        const { href } = this.$router.resolve({
          path: '/NewsDetail',
          query: {
            newsId: news.id
          }
        });
        window.open(href, '_blank');
      }
    },
    //图片切换
    imgchange() {
      if (this.chanpinTPsindex >= 4) {
        this.chanpinTPsindex = 0
      }

    },
    changechanpinTPsindex(index) {
      if (this.chanpinTPsindex == 0 || this.chanpinTPsindex == 3) {
        this.$message({
          message: '没有更多数据啦',
          type: 'warning'
        });
      } else {
        this.chanpinTPsindex += index
      }


    },
    getScreen() {
      if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      ) {
        this.isPC = true;
        this.pdbsf = 1;
        console.log("手机端");
      } else {
        this.isPC = false;
        this.pdbsf = 0;
        console.log("pc端");
      }
    },
    toHome(){
      this.$router.push('/home')
    }


  },


  mounted() {
    this.getScreen()
    // // 组件挂载后开始图片轮换  
    // this.pictureRotation();
    // this.startCarousel();
    this.getList();
  },
  beforeDestroy() {
    clearInterval()
  }, 
  created() {
    this.getList();
    setInterval(() => {
      this.chanpinTPsindex += 1
      this.imgchange()
    }, 2000);
  },




}
</script>
<style lang="scss" scoped>
.pagelg {
  overflow: hidden;
}

.newsNt {
  // position: relative;
  // top: -45px;
  // left: 120px;
}

.ydguanyuwomeng {
  // position: relative;
  // left: 0;
  width: 375px;
  height: 200px;
  margin: 0 auto;
}

.ydguanyuwomeng1 {
  position: relative;
  top: 0px;
  left: 0px;
}

.ydguanyuwomeng2 {
  position: relative;
  top: 0px;
  left: 0px;
}

.Pchome {
  text-align: center;

}

.Azhome {
  text-align: center;
}

.yd {

  position: relative;
}

.ydshouye {
  background-image: url('../assets/az/移动端背景1.png');
  margin: 0 auto;

  position: relative;

  height: 812px;
  width: 375px;
}

.ydbj1 {
  position: relative;
  width: 375px;
  height: 812px;

}

.ydbj2 {
  position: relative;
  width: 375px;
  height: 812px;
  top: -120px;
}

.ydHonorDisplay {
  top: 0px;
  background-image: url('../assets/az/荣誉展示图.png');
  margin: 0 auto;
  position: relative;
  width: 375px;
  max-width: 375px;
  height: 812px;
}

.ydzhengshu {
  position: relative;
  width: 336px;
  height: 368px;
  left: 0px;
  top: 300px;
}

.ydewm {
  position: relative;
  width: 74px;
  height: 74px;
  left: 20px;
}

.ydIosxz {
  position: relative;
  width: 74px;
  height: 30px;
  top: -44px;
  left: 42px;
}

.ydAndroidxz {
  position: relative;
  width: 74px;
  height: 30px;
  left: -32px;
}

.ydchanpin {

  margin: 0 auto;
  position: relative;
  width: 375px;
  max-width: 375px;
  height: 812px;
}

.chanpinTP1 {
  top: 0px;
  position: relative;
  width: 375px;
  height: 812px;

}

.dongxiaoTPs {
  position: relative;
  top: -200px;
  left: 0%;
}

.dongxiaoTP1 {
  position: relative;
  left: -150px;
  height: 24px;
  width: 24px;
}

.dongxiaoTP2 {
  position: relative;
  left: 150px;
  height: 24px;
  width: 24px;
}

.ydnewsbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 23px;
}

.ydnews {
  margin: 0 auto;
  // position: relative;
  // width: 375px;
  // max-width: 375px;
  // height: 920px;
  background-color: #ffffff;
}

.ydnewsP1 {
  color: #333333;
  font-size: 20px;
  font-family: 'PingFang SC-Medium';
  // position: relative;
  // left: -127px;
  // top: 0px;

}

.Azcard {
  width: 327px;
  height: 388px;
  margin-bottom: 40px;
  position: relative;
  left: 24px;
  top: -10px;
}

.Azimg {
  overflow: hidden;
  /* 隐藏超出容器的部分 */
  max-height: 240px;

  width: 100%;
  display: block;
  height: 240px;
}

.AzT1 {
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  text-align: left;

  position: relative;
  top: 10px;
  width: 303px;
  margin: auto;
  height: 46px;
  text-align: left;
  opacity: 1;
  display: -webkit-box;
  /* Safari */
  -webkit-line-clamp: 2;
  /* 限制显示的行数 */
  -webkit-box-orient: vertical;
  /* 设置文字垂直排列 */
  overflow: hidden;
  /* 隐藏超出的内容 */
  text-overflow: ellipsis;
  /* 显示省略号 */
  /*   width: 200px; /* 设置容器的宽度 */


}

.Azp1 {
  font-size: 14px;
  color: #999999;
  text-align: left;

  position: relative;
  top: 25px;
  width: 303px;
  margin: auto;
  height: 40px;
  text-align: left;
  opacity: 1;
  display: -webkit-box;
  /* Safari */
  -webkit-line-clamp: 2;
  /* 限制显示的行数 */
  -webkit-box-orient: vertical;
  /* 设置文字垂直排列 */
  overflow: hidden;
  /* 隐藏超出的内容 */
  text-overflow: ellipsis;
  /* 显示省略号 */


}

.Azp2 {
  text-align: left;
  font-size: 12px;
  white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  position: relative;
  top: 40px;
  left: -88px;
  width: 100px;
  height: 17px;
  margin: auto;

  color: #666666;
  text-align: left;
  opacity: 1;
}

// 屏幕大于 1024px 或小于 1440px 时应用该样式
@media screen and (min-width: 1440px) and (max-width: 1920px) {


  .logo1 {
    width: 160px;
    height: 64px;
    position: relative;
    top: -510px;
    left: 610px;
  }

  .logo2 {
    width: 160px;
    height: 64px;
    position: relative;
    top: -605px;
    left: 450px;
  }

  .logo3 {
    width: 160px;
    height: 160px;
    position: relative;
    top: -510px;
    left: 480px;
  }



  .logo5 {
    position: relative;
    top: -130px;
    left: -45%;
  }

  .logo6 {
    position: relative;
    top: -220px;
    left: 45%;
  }

  .mainbox {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 100%;

  }

  .mainbox4 {
    margin: 0 auto;
    position: relative;
    height: 1200px;
    width: 100%;
    max-width: 100%;
  }

  .ry1 {
    top: 0px;
    width: 920px;
    height: 175px;
    position: relative;
  }

  .p6 {
    top: 20px;
    color: #666666;
    font-size: 20px;
    position: relative;
  }

  .zs1 {
    z-index: 990;
    position: relative;
    top: 0px;
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  .bj2 {
    width: 100%;
    max-width: 100%;
    height: 900px;
    max-height: 900px;

  }

  .bj3 {
    position: relative;
    top: 20px;


    width: 100%;
    max-width: 100%;
    height: 100%;

    object-fit: contain;
  }

  .lgtitle {
    object-fit: contain;

    z-index: 998;
    position: fixed;
    background-color: rgba(51, 51, 51, 0.2);
    /* 20% 透明度的黑色背景 */

    border-bottom: 1px solid rgba(255, 255, 255, 0.5);

    width: 100%;
    max-width: 100%;
    height: 100px;

    top: 0;
    left: 0;
    opacity: 0.5;

    /* 确保固定在顶部的元素不会被其他内容遮挡 */
  }

  .lgtitlecontent {
    margin: 0 auto;

  }


  .mainbox1 {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 100%;
  }

  .mainbox2 {
    top: -140px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 1000px;
    background-color: #ed8f25;
    /* overflow: hidden; /* 如果需要，隐藏超出容器的内容 */
  }

  .mainbox3 {
    top: -300px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 350px;
    background-color: #ffffff;
    /* overflow: hidden; /* 如果需要，隐藏超出容器的内容 */
  }


  .mainbox1 img {
    width: 100%;
    max-width: 100%;
    height: 900px;
    object-fit: contain;
  }

  .img1 {
    width: 100%;
    max-width: 100%;
    height: auto;
    left: 100px;
    object-fit: contain;
  }

  .img5 {
    position: relative;
    top: 0110px;
    width: 100%;
    max-width: 1000px;
    height: auto;

    object-fit: contain;
  }

  .img11 {
    position: relative;
    top: -200px;
    width: 920px;
    max-width: 920px;
    height: 180px;

    object-fit: contain;
  }

  .img13 {
    width: 1000px;
    height: 5.5px;
    position: relative;
    top: 70px;
    left: 0px;
  }


  .img6 {
    position: relative;
    top: 80px;
    width: 600px;
    max-width: 600px;
    height: 178px;

    object-fit: contain;
  }



  .img8 {
    position: relative;
    top: 150px;
    width: 590px;
    max-width: 590px;
    height: 180px;

    object-fit: contain;
  }

  .img2 {
    position: relative;
    left: -500px;
    top: -240px;
    height: 130px;

  }

  .img9 {
    position: relative;
    left: 55px;
    top: 45px;
    height: 120px;
    width: 650px;

  }

  .img10 {
    position: relative;
    left: 55px;
    top: 45px;
    height: 120px;
    width: 650px;

  }

  .img3 {
    position: relative;
    /* 绝对定位使图片可以脱离文档流并覆盖在其他内容上 */
    max-width: 100%;
    top: 50px;
    /* 最大宽度设置为父元素宽度的100% */
    /* height: auto; /* 高度自动调整 */
  }

  .img4 {
    // width: 750px;
    // height: 896px;
    max-width: 288px;

    /* height: auto; /* 高度自动调整 */
    position: absolute;
    /* 图片设置为绝对定位 */
    top: -1770px;
    /* 距离容器顶部的距离 */
    left: 60%;
    /* 距离容器左侧的距离 */
    overflow: hidden;
  }

  .img4 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    /* 保持图片原始宽高比 */
  }

  .text1 {

    width: 80%;
    margin: auto;
    text-align: center;
    /* 水平居中 */

    position: relative;
    top: 280px;
    padding: 0;
    margin: 0;
    padding-left: 10%;
    padding-right: 10%;
  }

  .text2 {
    font-size: 44px;
    position: relative;
    top: -200px;
    left: -400px;
  }



  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {

    overflow: hidden;
    /* 隐藏超出容器的部分 */
    max-height: 200px;

    width: 100%;
    display: block;
    height: 200px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .bj1 {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5)
  }

  .img2 img {
    /* 移除图片底部的默认空白 */
    vertical-align: middle;
    /* 如果需要，设置图片的最大宽度为100%以适应容器 */
    max-width: 100%;
    height: auto;
    /* 保持图片的原始纵横比 */
  }

  .img2 H3,
  .img2 H6 {
    position: relative;
    left: 200px;
    top: -120px;
    /* 移除标题的margin和padding */
    margin: 0;
    padding: 0;
    /* 如果需要，设置字体大小和行高等样式 */
  }

  .pt1 {

    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 12px;
    left: 50%;
    /* 使用十六进制颜色代码 */
  }

  .pt2 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 12px;
    left: 60%;
    /* 使用十六进制颜色代码 */
  }

  .pt3 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 12px;
    left: 70%;
    /* 使用十六进制颜色代码 */
  }

  .pt4 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 12px;
    left: 80%;
    /* 使用十六进制颜色代码 */
  }

  .pty1 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 12px;
    left: 50%;

  }

  .pty2 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 12px;
    left: 60%;

  }

  .pty3 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 12px;
    left: 70%;
  }

  .pty4 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 12px;
    left: 80%;

  }

  .p1 {

    object-fit: contain;

    /* 使用像素值 */
    color: #666666;
    text-align: left;
    line-height: 1.6;
    /* 设置行高为字体大小的1.6倍 */
    /* 使用十六进制颜色代码 */
  }



  .ph1 {
    width: 280px;
    font-size: 33px;

    color: #ed8f25;
    text-align: left;
    margin: auto;
    opacity: 1;
  }

  .ph2 {
    width: 280px;
    font-size: 22px;
    color: #ed8f25;
    text-align: left;
    margin: auto;
    opacity: 1;
    position: relative;
    top: -5px
  }

  .p4 {
    text-align: left;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: 0px;
    width: 280px;
    margin: auto;

    color: #666666;
    text-align: left;
    opacity: 1;
  }

  .p5 {
    text-align: left;
    font-size: 30px;
    position: relative;
    top: -5px;
    width: 280px;
    margin: auto;
    font-size: 30px;
    text-align: left;
    opacity: 1;
    display: -webkit-box;
    /* Safari */
    -webkit-line-clamp: 2;
    /* 限制显示的行数 */
    -webkit-box-orient: vertical;
    /* 设置文字垂直排列 */
    overflow: hidden;
    /* 隐藏超出的内容 */
    text-overflow: ellipsis;
    /* 显示省略号 */

    height: auto;
    /* 允许容器高度自动调整以适应内容 */


  }

  p {
    cursor: pointer;
    /* 显示手型光标，表示元素是可点击的 */
  }



  .img12 {
    position: relative;
    top: 10px;
    left: -85px;
    width: 120px;
    height: 40px;
  }

  .card {
    width: 340px;
    height: 450px;
    margin-bottom: 0px;
    position: relative;
    left: 60%;
    top: 150px;

  }

  .card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /* 鼠标悬停时添加阴影 */
  }
}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .i1024 {
    width: 1;
  }

  .mainbox4 {
    margin: 0 auto;
    position: relative;
    height: 850px;
    width: 100%;
    max-width: 100%;
  }

  .ry1 {
    top: 0px;
    width: 667px;
    height: 128px;
    position: relative;
  }

  .p6 {
    top: 0px;
    color: #666666;
    font-size: 14px;
    position: relative;
  }

  .zs1 {
    z-index: 990;
    position: relative;
    top: 0px;
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  .bj3 {
    position: relative;
    top: 20px;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    object-fit: contain;
  }

  .logo5 {
    position: relative;
    top: -148px;
    left: -45%;
  }

  .logo6 {
    position: relative;
    top: -230px;
    left: 45%;
  }

  .mainbox {
    margin: 0 auto;
    position: relative;
    width: 100%;

  }

  .lgtitle {
    z-index: 998;
    position: fixed;
    background-color: rgba(51, 51, 51, 0.2);
    /* 20% 透明度的黑色背景 */

    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    // background-color: #ffffff;
    width: 100%;
    max-width: 100%;
    // max-width: 1440px;
    height: 75px;
    // position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    // z-index: 999;
    /* 确保固定在顶部的元素不会被其他内容遮挡 */
  }

  .lgtitlecontent {
    margin: 0 auto;

  }


  .mainbox1 {
    margin: 0 auto;
    position: relative;
    width: 100%;
    // max-width: 1440px;
  }

  .mainbox2 {
    top: -70px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    // max-width: 1440px;
    height: 1100px;
    background-color: #ed8f25;
    /* overflow: hidden; /* 如果需要，隐藏超出容器的内容 */
  }

  .mainbox3 {
    top: -300px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
    height: 350px;
    background-color: #ffffff;
    /* overflow: hidden; /* 如果需要，隐藏超出容器的内容 */
  }


  .bj2 {
    width: 100%;
    max-width: 100%;
    height: 80%;
  }

  .mainbox1 img {
    width: 100%;
    max-width: 1920px;
    height: 900px;
    object-fit: contain;
  }

  .img1 {
    width: 100%;
    max-width: 1920px;
    height: auto;
    left: 100px;
    object-fit: contain;
  }

  .img5 {
    position: relative;
    top: 0110px;
    width: 100%;
    max-width: 1000px;
    height: auto;

    object-fit: contain;
  }

  .img11 {
    position: relative;
    top: -150px;
    width: 672px;
    max-width: 672px;
    height: 148px;

    object-fit: contain;
  }


  .img6 {
    position: relative;
    top: 80px;
    width: 437px;
    max-width: 437px;
    height: 128px;

    object-fit: contain;
  }


  .img8 {
    position: relative;
    top: 150px;
    width: 590px;
    max-width: 590px;
    height: 180px;

    object-fit: contain;
  }

  .img2 {
    position: relative;
    left: -290px;
    top: -200px;
    height: 100px;

  }

  .img13 {
    width: 950px;
    height: 4px;
    position: relative;
    top: 20px;
    left: 0px;
  }

  .img9 {
    position: relative;
    left: 55px;
    top: 45px;
    height: 120px;
    width: 650px;

  }

  .img10 {
    position: relative;
    left: 55px;
    top: 45px;
    height: 120px;
    width: 650px;

  }

  .img3 {
    position: relative;
    /* 绝对定位使图片可以脱离文档流并覆盖在其他内容上 */
    max-width: 100%;
    top: 50px;
    /* 最大宽度设置为父元素宽度的100% */
    /* height: auto; /* 高度自动调整 */
  }

  .img4 {
    width: 586px;
    // height: 700px;
    max-width: 586px;

    /* height: auto; /* 高度自动调整 */
    position: absolute;
    /* 图片设置为绝对定位 */
    top: -1810px;
    /* 距离容器顶部的距离 */
    left: 50%;
    /* 距离容器左侧的距离 */
    overflow: hidden;
  }

  .img4 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    /* 保持图片原始宽高比 */
  }

  .text1 {
    width: 80%;
    margin: auto;
    text-align: center;
    /* 水平居中 */

    position: relative;
    top: 280px;
    padding: 0;
    margin: 0;
    padding-left: 10%;
    padding-right: 10%;
  }

  .text2 {
    font-size: 32px;
    position: relative;
    top: -140px;
    left: -300px;
  }

  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {

    overflow: hidden;
    /* 隐藏超出容器的部分 */
    max-height: 160px;

    width: 100%;
    display: block;
    height: 194px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .bj1 {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5)
  }

  .img2 img {
    /* 移除图片底部的默认空白 */
    vertical-align: middle;
    /* 如果需要，设置图片的最大宽度为100%以适应容器 */
    max-width: 100%;
    height: auto;
    /* 保持图片的原始纵横比 */
  }

  .img2 H3,
  .img2 H6 {
    position: relative;
    left: 200px;
    top: -120px;
    /* 移除标题的margin和padding */
    margin: 0;
    padding: 0;
    /* 如果需要，设置字体大小和行高等样式 */
  }

  .p1 {
    object-fit: contain;
    // width: 1300px;
    // font-size: 28px;
    /* 使用像素值 */
    color: #666666;
    text-align: left;
    line-height: 1.6;
    /* 设置行高为字体大小的1.6倍 */
    /* 使用十六进制颜色代码 */
  }

  .pt1 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 50%;
    /* 使用十六进制颜色代码 */
  }

  .pt2 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 60%;
    /* 使用十六进制颜色代码 */
  }

  .pt3 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 70%;
    /* 使用十六进制颜色代码 */
  }

  .pt4 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 80%;
    /* 使用十六进制颜色代码 */
  }

  .pty1 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 50%;

  }

  .pty2 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 60%;

  }

  .pty3 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 70%;
  }

  .pty4 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 80%;

  }

  .ph1 {
    width: 220px;
    font-size: 24px;

    color: #ed8f25;
    text-align: left;
    margin: auto;
    opacity: 1;
  }

  .ph2 {
    width: 220px;
    font-size: 14px;
    color: #ed8f25;
    text-align: left;
    margin: auto;
    opacity: 1;
    position: relative;
    top: 0px
  }

  .p4 {
    text-align: left;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: 30px;
    width: 220px;
    margin: auto;

    color: #666666;
    text-align: left;
    opacity: 1;
  }

  .p5 {
    text-align: left;
    font-size: 22px;
    position: relative;
    top: 10px;
    width: 220px;
    margin: auto;
    font-size: 30px;
    text-align: left;
    opacity: 1;
    display: -webkit-box;
    /* Safari */
    -webkit-line-clamp: 2;
    /* 限制显示的行数 */
    -webkit-box-orient: vertical;
    /* 设置文字垂直排列 */
    overflow: hidden;
    /* 隐藏超出的内容 */
    text-overflow: ellipsis;
    /* 显示省略号 */

    height: auto;
    /* 允许容器高度自动调整以适应内容 */


  }

  p {
    cursor: pointer;
    /* 显示手型光标，表示元素是可点击的 */
  }



  .img12 {
    position: relative;
    top: 40px;
    left: -70px;
    width: 88px;
    height: 28px;
  }

  .card {
    width: 280px;
    height: 390px;
    margin-bottom: 0px;
    position: relative;
    left: 50%;
    top: 130px;

  }

  .card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /* 鼠标悬停时添加阴影 */
  }

  .logo1 {
    width: 116px;
    height: 46px;
    position: relative;
    top: -370px;
    left: 385px;
  }

  .logo2 {
    width: 116px;
    height: 46px;
    position: relative;
    top: -439px;
    left: 270px;
  }

  .logo3 {
    width: 116px;
    height: 116px;
    position: relative;
    top: -370px;
    left: 310px;
  }


}

@media screen and (min-width: 1921px) {

  .ry1 {
    top: 0px;
    width: 920px;
    height: 175px;
    position: relative;
  }

  .p6 {
    top: 100px;
    color: #666666;
    font-size: 20px;
    position: relative;
  }

  .zs1 {
    z-index: 990;
    position: relative;
    top: 100px;
    height: auto;
    width: 1920px;
    max-width: 1920px;
  }

  .mainbox4 {
    margin: 0 auto;
    position: relative;
    background-color: #ffffff;
    width: 1920px;
    max-width: 1920px;
    height: 1300px;
  }

  .bj3 {
    position: relative;
    top: 20px;


    width: 100%;
    max-width: 1920px;
    height: 100%;

    object-fit: contain;
  }

  .logo1 {
    width: 160px;
    height: 64px;
    position: relative;
    top: -510px;
    left: 760px;
  }

  .logo2 {
    width: 160px;
    height: 64px;
    position: relative;
    top: -605px;
    left: 600px;
  }

  .logo3 {
    width: 160px;
    height: 160px;
    position: relative;
    top: -510px;
    left: 635px;
  }



  .logo5 {
    position: relative;
    top: -228px;
    left: -870px;
  }

  .logo6 {
    position: relative;
    top: -310px;
    left: 870px;
  }

  .mainbox {
    margin: 0 auto;
    position: relative;
    width: 1920px;
    max-width: 1920px;
    // top: -100px;
  }

  .bj2 {
    width: 1920px;
    max-width: 1920px;
    height: 880px;

  }

  .dw {
    position: fixed;
    width: 1920px;
    /* 保留固定宽度 */
    max-width: 1920px;
    /* 保留最大宽度 */
    height: 100px;
    z-index: 998;
  }

  .hh {
    position: fixed;
    width: 1920px;
    /* 保留固定宽度 */
    max-width: 1920px;
    /* 保留最大宽度 */
    height: 100px;
    // opacity: 0.5;  
    z-index: 998;
    /* 水平居中 */
    left: 50%;
    transform: translateX(-50%);
    /* 垂直居中 */
    top: 3%;
    transform: translateY(-50%) translateX(-50%);
    /* 合并两个transform属性 */
  }

  .lgtitle {
    z-index: 997;
    position: fixed;
    background-color: rgba(51, 51, 51, 0.2);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    width: 1920px;
    /* 保留固定宽度 */
    max-width: 1920px;
    /* 保留最大宽度 */
    height: 100px;
    opacity: 0.5;
    /* 水平居中 */
    left: 50%;
    transform: translateX(-50%);

    /* 垂直居中 */
    top: 3%;
    transform: translateY(-50%) translateX(-50%);
    /* 合并两个transform属性 */
  }

  .lgtitlecontent {
    margin: 0 auto;

  }


  .mainbox1 {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
  }

  .mainbox2 {
    top: -140px;
    margin: 0 auto;
    position: relative;
    width: 1920px;
    max-width: 1920px;
    height: 1260px;
    background-color: #ed8f25;
    /* overflow: hidden; /* 如果需要，隐藏超出容器的内容 */
  }

  .mainbox3 {
    top: -300px;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
    height: 350px;
    background-color: #ffffff;
    /* overflow: hidden; /* 如果需要，隐藏超出容器的内容 */
  }


  .mainbox1 img {
    width: 100%;
    max-width: 1920px;
    height: 900px;
    object-fit: contain;
  }

  .img1 {
    width: 100%;
    max-width: 1920px;
    height: auto;
    left: 100px;
    object-fit: contain;
  }

  .img5 {
    position: relative;
    top: 0110px;
    width: 100%;
    max-width: 1000px;
    height: auto;

    object-fit: contain;
  }

  .img11 {
    position: relative;
    top: -200px;
    width: 920px;
    max-width: 920px;
    height: 180px;

    object-fit: contain;
  }

  .img13 {
    width: 1300px;
    height: 5.5px;
    position: relative;
    top: 70px;
    left: 0px;
  }


  .img6 {
    position: relative;
    top: 80px;
    width: 600px;
    max-width: 600px;
    height: 178px;

    object-fit: contain;
  }



  .img8 {
    position: relative;
    top: 150px;
    width: 590px;
    max-width: 590px;
    height: 180px;

    object-fit: contain;
  }

  .img2 {
    position: relative;
    left: -500px;
    top: -240px;
    height: 130px;

  }

  .img9 {
    position: relative;
    left: 55px;
    top: 45px;
    height: 120px;
    width: 650px;

  }

  .img10 {
    position: relative;
    left: 55px;
    top: 45px;
    height: 120px;
    width: 650px;

  }

  .img3 {
    position: relative;
    /* 绝对定位使图片可以脱离文档流并覆盖在其他内容上 */
    max-width: 100%;
    top: 50px;
    /* 最大宽度设置为父元素宽度的100% */
    /* height: auto; /* 高度自动调整 */
  }

  .img4 {
    // width: 810px;
    // height: 960px;
    max-width: 810px;

    /* height: auto; /* 高度自动调整 */
    position: absolute;
    /* 图片设置为绝对定位 */
    top: -2200px;
    /* 距离容器顶部的距离 */
    left: 50%;
    /* 距离容器左侧的距离 */
    overflow: hidden;
  }

  .img4 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    /* 保持图片原始宽高比 */
  }

  .text1 {
    width: 80%;
    margin: auto;
    text-align: center;
    /* 水平居中 */

    position: relative;
    top: 280px;
    padding-left: 10%;
    padding-right: 10%;
    margin: 0;

  }

  .text2 {
    font-size: 44px;
    position: relative;
    top: -200px;
    left: -400px;
  }



  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {

    // overflow: hidden;
    /* 隐藏超出容器的部分 */
    max-height: 263px;

    width: 440px;
    display: block;
    height: 263px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .bj1 {
    background: rgba(0, 0, 0, 0.1);
    width: 100%;
    height: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5)
  }

  .img2 img {
    /* 移除图片底部的默认空白 */
    vertical-align: middle;
    /* 如果需要，设置图片的最大宽度为100%以适应容器 */
    max-width: 100%;
    height: auto;
    /* 保持图片的原始纵横比 */
  }

  .img2 H3,
  .img2 H6 {
    position: relative;
    left: 200px;
    top: -120px;
    /* 移除标题的margin和padding */
    margin: 0;
    padding: 0;
    /* 如果需要，设置字体大小和行高等样式 */
  }

  .pt1 {

    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 22px;
    left: 1000px;
    /* 使用十六进制颜色代码 */
  }

  .pt2 {

    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 22px;
    left: 1150px;
    /* 使用十六进制颜色代码 */
  }

  .pt3 {

    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 22px;
    left: 1350px;
    /* 使用十六进制颜色代码 */
  }

  .pt4 {

    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 22px;
    left: 1550px;
    /* 使用十六进制颜色代码 */
  }

  .pty1 {

    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 22px;
    left: 1000px;

  }

  .pty2 {

    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 22px;
    left: 1150px;

  }

  .pty3 {

    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 22px;
    left: 1350px;
  }

  .pty4 {

    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 22px;
    left: 1550px;

  }

  .p1 {
    object-fit: contain;
    // font-size: 28px;
    /* 使用像素值 */
    color: #666666;
    text-align: left;
    line-height: 1.6;
    /* 设置行高为字体大小的1.6倍 */
    /* 使用十六进制颜色代码 */
  }



  .ph1 {
    width: 360px;
    font-size: 33px;

    color: #ed8f25;
    text-align: left;
    margin: auto;
    opacity: 1;
  }

  .ph2 {
    width: 360px;
    font-size: 22px;
    color: #ed8f25;
    text-align: left;
    margin: auto;
    opacity: 1;
    position: relative;
    top: 5px
  }

  .p4 {
    text-align: left;
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: 45px;
    width: 360px;
    margin: auto;

    color: #666666;
    text-align: left;
    opacity: 1;
  }

  .p5 {
    text-align: left;
    font-size: 30px;
    position: relative;
    top: 20px;
    width: 360px;
    margin: auto;
    font-size: 30px;
    text-align: left;
    opacity: 1;
    display: -webkit-box;
    /* Safari */
    -webkit-line-clamp: 2;
    /* 限制显示的行数 */
    -webkit-box-orient: vertical;
    /* 设置文字垂直排列 */
    overflow: hidden;
    /* 隐藏超出的内容 */
    text-overflow: ellipsis;
    /* 显示省略号 */

    height: auto;
    /* 允许容器高度自动调整以适应内容 */


  }

  p {
    cursor: pointer;
    /* 显示手型光标，表示元素是可点击的 */
  }



  .img12 {
    position: relative;
    top: 75px;
    left: -121px;
    width: 120px;
    height: 40px;
  }

  .card {
    width: 440px;
    height: 600px;
    margin-bottom: 0px;
    position: relative;
    left: 252px;
    top: 150px;

  }

  .card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /* 鼠标悬停时添加阴影 */
  }


}

@media screen and (max-width: 1024px) {
  .bj3 {
    position: relative;
    top: 20px;


    width: 100%;
    max-width: 1024px;
    height: 100%;

    object-fit: contain;
  }

  .logo5 {
    position: relative;
    top: -68px;
    left: -45%;
    width: 50px;
    height: 50px;
  }

  .logo6 {
    position: relative;
    top: -130px;
    left: 45%;
    width: 50px;
    height: 50px;
  }

  .mainbox {
    margin: 0 auto;
    position: relative;
    width: 1024px;
    max-width: 1024px;
    // top: -100px;
  }

  .lgtitle {
    z-index: 998;
    position: fixed;
    background-color: rgba(51, 51, 51, 0.2);
    /* 20% 透明度的黑色背景 */

    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    // background-color: #ffffff;
    width: 1024px;
    // max-width: 1440px;
    height: 75px;
    // position: absolute;
    top: 0;
    left: 0;
    opacity: 0.5;
    // z-index: 999;
    /* 确保固定在顶部的元素不会被其他内容遮挡 */
  }

  .lgtitlecontent {
    margin: 0 auto;

  }


  .mainbox1 {
    margin: 0 auto;
    position: relative;
    width: 100%;
    // max-width: 1440px;
  }

  .mainbox2 {
    top: -70px;
    margin: 0 auto;
    position: relative;
    width: 1024px;
    // max-width: 1440px;
    height: 570px;
    background-color: #ed8f25;
    /* overflow: hidden; /* 如果需要，隐藏超出容器的内容 */
  }

  .mainbox3 {
    top: -300px;
    margin: 0 auto;
    position: relative;
    width: 1024px;
    max-width: 1024px;
    height: 350px;
    background-color: #ffffff;
    /* overflow: hidden; /* 如果需要，隐藏超出容器的内容 */
  }


  .bj2 {
    width: 1024px;
    max-width: 1024px;
    height: 512px;
  }

  .mainbox1 img {
    width: 100%;
    max-width: 1024px;
    height: 900px;
    object-fit: contain;
  }

  .img1 {
    width: 100%;
    max-width: 1024px;
    height: auto;
    left: 100px;
    object-fit: contain;
  }

  .img5 {
    position: relative;
    top: 0110px;
    width: 100%;
    max-width: 1000px;
    height: auto;

    object-fit: contain;
  }

  .img11 {
    position: relative;
    top: -150px;
    width: 490px;
    max-width: 490px;
    height: 132px;

    object-fit: contain;
  }


  .img6 {
    position: relative;
    top: 80px;
    width: 320px;
    max-width: 320px;
    height: 94px;

    object-fit: contain;
  }

  .img7 {
    position: relative;
    top: -60px;
    width: 100%;
    max-width: 1024px;
    height: 100%;
    object-fit: contain;
  }

  .img7 {
    position: relative;
    top: -100px;
    width: 100%;
    max-width: 1920px;
    height: 100%;
    object-fit: contain;
  }

  .img7 {
    position: relative;
    top: -100px;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .img7 {
    position: relative;
    top: -80px;
    width: 100%;
    max-width: 1440px;
    height: 100%;
    object-fit: contain;
  }

  .img8 {
    position: relative;
    top: 250px;
    width: 300px;
    max-width: 300px;
    height: 128px;
    object-fit: contain;
  }

  .img2 {
    position: relative;
    left: -290px;
    top: -200px;
    height: 80px;

  }

  .img13 {
    width: 620px;
    height: 3px;
    position: relative;
    top: 25px;
    left: 0px;
  }

  .img9 {
    position: relative;
    left: 95px;
    top: 45px;
    height: 87px;
    width: 475px;

  }

  .img10 {
    position: relative;
    left: 95px;
    top: 45px;
    height: 87px;
    width: 475px;

  }

  .img3 {
    position: relative;
    /* 绝对定位使图片可以脱离文档流并覆盖在其他内容上 */
    max-width: 100%;
    top: 50px;
    /* 最大宽度设置为父元素宽度的100% */
    /* height: auto; /* 高度自动调整 */
  }

  .img4 {
    width: 288px;
    // height: 514px;
    max-width: 288px;

    /* height: auto; /* 高度自动调整 */
    position: absolute;
    /* 图片设置为绝对定位 */
    top: -1160px;
    /* 距离容器顶部的距离 */
    left: 450px;
    /* 距离容器左侧的距离 */
    overflow: hidden;
  }

  .img4 img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    /* 保持图片原始宽高比 */
  }

  .text1 {
    object-fit: contain;
    width: 80%;
    /* 水平居中 */

    position: relative;
    top: 280px;
    padding: 0;
    margin: 0;
    padding-left: 10%;
    padding-right: 10%;

  }

  .text2 {
    font-size: 24px;
    position: relative;
    top: -140px;
    left: -230px;
  }

  .bottom {
    margin-top: 13px;
    line-height: 12px;
  }

  .button {
    padding: 0;
    float: right;
  }

  .image {

    overflow: hidden;
    /* 隐藏超出容器的部分 */
    max-height: 120px;

    width: 200px;
    display: block;
    height: 140px;
  }

  .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }

  .clearfix:after {
    clear: both
  }

  .bj1 {
    background: rgba(0, 0, 0, 0.1);
    width: 1024px;
    height: 100px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5)
  }

  .img2 img {
    /* 移除图片底部的默认空白 */
    vertical-align: middle;
    /* 如果需要，设置图片的最大宽度为100%以适应容器 */
    max-width: 1024px;
    height: auto;
    /* 保持图片的原始纵横比 */
  }

  .img2 H3,
  .img2 H6 {
    position: relative;
    left: 200px;
    top: -120px;
    /* 移除标题的margin和padding */
    margin: 0;
    padding: 0;
    /* 如果需要，设置字体大小和行高等样式 */
  }

  .p1 {
    // object-fit: contain;

    // font-size: 28px;
    /* 使用像素值 */
    color: #666666;
    text-align: left;
    line-height: 1.6;
    object-fit: contain;
    /* 设置行高为字体大小的1.6倍 */
    /* 使用十六进制颜色代码 */
  }

  .pt1 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 550px;
    /* 使用十六进制颜色代码 */
  }

  .pt2 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 650px;
    /* 使用十六进制颜色代码 */
  }

  .pt3 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 750px;
    /* 使用十六进制颜色代码 */
  }

  .pt4 {
    font-size: 22px;
    /* 使用像素值 */
    color: #f2f2f2;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 850px;
    /* 使用十六进制颜色代码 */
  }

  .pty1 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 550px;

  }

  .pty2 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 650px;

  }

  .pty3 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 750px;
  }

  .pty4 {
    font-weight: bold;
    font-size: 22px;
    color: #ed8f25;
    z-index: 999;
    position: fixed;
    top: 2px;
    left: 850px;

  }

  .ph1 {
    width: 180px;
    font-size: 17px;

    color: #ed8f25;
    text-align: left;
    margin: auto;
    opacity: 1;
    height: 10px;
    position: relative;
    top: -10px;
  }

  .ph2 {
    width: 180px;
    font-size: 10px;
    color: #ed8f25;
    text-align: left;
    margin: auto;
    opacity: 1;
    position: relative;
    top: 5px
  }

  .p4 {
    text-align: left;
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    top: 20px;
    width: 180px;
    margin: auto;

    color: #666666;
    text-align: left;
    opacity: 1;
  }

  .p5 {
    text-align: left;
    font-size: 15px;
    position: relative;
    top: 10px;
    width: 180px;
    margin: auto;

    text-align: left;
    opacity: 1;
    display: -webkit-box;
    /* Safari */
    -webkit-line-clamp: 2;
    /* 限制显示的行数 */
    -webkit-box-orient: vertical;
    /* 设置文字垂直排列 */
    overflow: hidden;
    /* 隐藏超出的内容 */
    text-overflow: ellipsis;
    /* 显示省略号 */

    height: auto;
    /* 允许容器高度自动调整以适应内容 */


  }

  p {
    cursor: pointer;
    /* 显示手型光标，表示元素是可点击的 */
  }


  .img12 {
    position: relative;
    top: 30px;
    left: -60px;
    width: 60px;
    height: 22px;
  }

  .card {
    width: 200px;
    height: 280px;
    margin-bottom: 0px;
    position: relative;
    left: 60%;
    top: 110px;

  }

  .card:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    /* 鼠标悬停时添加阴影 */
  }

  .logo1 {
    width: 85px;
    height: 32px;
    position: relative;
    top: -280px;
    left: 325px;
  }

  .logo2 {
    width: 85px;
    height: 32px;
    position: relative;
    top: -330px;
    left: 240px;
  }

  .logo3 {
    width: 85px;
    height: 85px;
    position: relative;
    top: -280px;
    left: 260px;
  }




  .mainbox4 {
    margin: 0 auto;
    position: relative;
    height: 600px;
    width: 100%;
    max-width: 100%;
  }

  .ry1 {
    top: 0px;
    width: 475px;
    height: 92px;
    position: relative;
  }

  .p6 {
    top: 0px;
    color: #666666;
    font-size: 10px;
    position: relative;
  }

  .zs1 {
    z-index: 990;
    position: relative;
    top: 0px;
    height: auto;
    width: 1024px;
    max-width: 1024px;
  }

}

.logo4 {
  position: fixed;
  width: 150px;
  height: auto;
  left: 10px;
  top: -30px;
  z-index: 999;
}

// .logo4 {
//   position: fixed;
//   width: 183px;
//   height: auto;
//   left: 20px;
//   top: 17px;
//   z-index: 999;
// }
// .logo4 {

//   position: fixed;
//   width: 183px;
//   height: auto;
//   left: 20px;
//   top: 12px;
//   z-index: 999;

// }
// .logo4 {
//   position: fixed;
//   width: 183px;
//   height: auto;
//   left: 20px;
//   top: 12px;
//   z-index: 999;

// }
.img9font{
  color: #333333;
  font-size: 20px;
  font-weight: 600;
}
.img10font{
  color: #ed8f25;
  font-size: 40px;
  font-weight: 800;
}
</style>
